<template>
  <div @click="badgeClickedAll">
    <v-badge
      style="cursor: pointer;"
      overlap
      top
      color="primary"
    >
      <span
        slot="badge"
        @click="badgeClicked"
      ><strong>?</strong>
      </span>
      <slot></slot>
    </v-badge>
  </div>
</template>

<script>
export default {
  setup(_, { emit }) {
    const badgeClickedAll = () => {
      emit('clickAll')
    }
    const badgeClicked = () => {
      emit('click')
    }

    return {
      badgeClickedAll,
      badgeClicked,
    }
  },
}
</script>
